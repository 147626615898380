import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api, { ENDPOINTS } from "../../utils/axios";

const userModel = {
  //DATA
  userId: null,
  lang: null,
  isLoading: true,
  isRequestAllowed: true,
  access_token: null,
  refresh_token: null,
  token: null,
  nickname: null,
  error: false,
  points: null,
  rank: null,
  profileImage: null,
  newAccountSportWorld: null,
  favoriteTeamSW: null,
  country: null,

  // ACTIONS
  setUserId: action((state, payload) => {
    state.userId = payload;
  }),
  setLang: action((state, payload) => {
    state.lang = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setAccessToken: action((state, access_token) => {
    state.access_token = access_token;
  }),
  setRefreshToken: action((state, refresh_token) => {
    state.refresh_token = refresh_token;
  }),
  setRefreshedToken: action((state, newAccessToken) => {
    state.access_token = newAccessToken;
  }),
  setEmail: action((state, email) => {
    state.email = email;
  }),
  setRequestAllowed: action((state, payload) => {
    state.isRequestAllowed = payload;
  }),
  setPoints: action((state, points) => {
    state.points = points;
  }),
  setRank: action((state, rank) => {
    state.rank = rank;
  }),
  setNickname: action((state, nickname) => {
    state.nickname = nickname;
  }),
  setNicknameError: action((state, val) => {
    state.error = val;
  }),
  setProfileImage: action((state, image) => {
    state.profileImage = image;
  }),
  setNewAccountSportWorld: action((state, newAccountSportWorld) => {
    state.newAccountSportWorld = newAccountSportWorld;
  }),
  setCountry: action((state, country) => {
    state.country = country;
  }),
  setFavoriteTeam: action((state, favoriteTeam) => {
    state.favoriteTeam = favoriteTeam;
  }),
  setToken: action((state, tokenPayload) => {
    if (tokenPayload) {
      const { accessToken, refreshToken } = tokenPayload;
      state.access_token = accessToken;
      state.refresh_token = refreshToken;
      api.defaults.headers.common["Authorization"] = `Bearer ${state.access_token}`;
    } else {
      state.access_token = null;
      state.refresh_token = null;
      state.isLoading = false;
    }
  }),

  // -----------------------------------------------------------------------------------
  //THUNKS
  login: thunk(async (actions, { token, lang }) => {
    const payload = { token, lang };
    actions.setLang(lang);
    actions.setIsLoading(true);
    actions.setToken(token);

    try {
      const { data } = await api.post(ENDPOINTS.AUTH.AUTHENTICATION, payload);

      const { _id, nickname, profilePicture, newAccountSportWorld, country } = data;

      actions.setToken(data);
      actions.setUserId(_id);
      actions.setNickname(nickname);
      actions.setProfileImage(profilePicture);
      actions.setNewAccountSportWorld(newAccountSportWorld);
      actions.setCountry(country);
    } catch (error) {
      console.error(error);
    }
    actions.setIsLoading(false);
  }),
  getLang: thunk(async (actions, lang) => {
    actions.setLang(lang);
  }),
  getUserStats: thunk(async (actions, chalangeId, { getState }) => {
    const { isRequestAllowed } = getState();

    if (!isRequestAllowed) {
      return;
    }

    try {
      actions.setRequestAllowed(false);
      const { data } = await api.get(
        ENDPOINTS.AUTH.POINTS_AND_RANK + "?challengeId=" + chalangeId
      );

      if (data) {
        actions.setPoints(data?.allTimeUserRanking?.points?.toFixed(2) || 0);
        actions.setRank(data?.allTimeUserRanking?.position);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        actions.setRequestAllowed(true);
      }, 60000); 
    }
  }),
  getRefreshedToken: thunk(async (actions, skip, { getState }) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${getState().refresh_token}`;
      const { data } = await api.post(ENDPOINTS.AUTH.REFRESH_TOKEN);

      actions.setRefreshedToken(data.accessToken);
      return data.accessToken;
    } catch (error) {
      console.error(error);
    }
  }),
};

export default userModel;
